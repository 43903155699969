import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box as="footer" width="full" py="5">
      <Flex justifyContent="center" alignItems="center">
        <Text fontSize="md">
            Fully decentralized and made with ❤️ for Self-Sovereign Identity © 2024 <a href="https://otoco.io/dashpanel/entity/base:34" target="_blank" rel="noopener noreferrer">dgenDID LLC</a>.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
